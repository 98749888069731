import React, { useEffect, useRef, useState } from 'react';
import SimpleViewsLayout from 'components/templates/SimpleViewsLayout/SimpleViewsLayout';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { AddFoodTrucks, Foodtrucks, Wrapper } from './MyFoodtrucks.styles';
import plus from 'assets/icons/plus.svg';
import FoodtruckBox from 'components/molecules/FoodtruckBox/FoodtruckBox';
import ReactDOM from 'react-dom';
import CreateFoodtruckMenu from 'components/molecules/CreateFoodtruckMenu/CreateFoodtruckMenu';
import { FoodtruckState } from 'types/Foodtrucktypes';
import { exampleFoodtrucks } from 'data/foodtruck';

const MyFoodtrucks = () => {
  const body = document.querySelector('body');
  const [cookies] = useCookies(['user-token']);
  const [currentFoodtruck, setCurrentFoodtruck] = useState<FoodtruckState | null>(null);
  const navigate = useNavigate();
  const creatMenu = useRef<HTMLDivElement>();

  useEffect(() => {
    if (!cookies['user-token']) navigate('/app/login');
  }, [cookies['user-token']]);

  const onEditMenuOpen = () => {
    setCurrentFoodtruck(exampleFoodtrucks[1]);
    setTimeout(() => {
      const menu = creatMenu.current as HTMLDivElement;
      menu.classList.remove('translate-y-full');
      menu.classList.add('animate-down_to_top');
      setTimeout(() => {
        menu.classList.add('translate-y-0');
        menu.classList.remove('animate-down_to_top');
      }, 400);
    }, 200);
  };

  const onEditMenuClose = () => {
    const menu = creatMenu.current as HTMLDivElement;
    menu.classList.remove('translate-y-0');
    menu.classList.add('animate-top_to_down');
    setTimeout(() => {
      menu.classList.add('translate-y-full');
      menu.classList.remove('animate-top_to_down');
      setCurrentFoodtruck(null);
    }, 400);
  };

  return (
    <>
      <SimpleViewsLayout>
        <Wrapper>
          <Foodtrucks />
          <p className="font-semibold text-lg mb-20">Moje Foodtrucki</p>
          <div className="grid gap-8 justify-items-center">
            <FoodtruckBox foodtruck={exampleFoodtrucks[1]} onEditClick={onEditMenuOpen} />
            <AddFoodTrucks
              to={`/app/${
                exampleFoodtrucks[1].urlName
                  ? exampleFoodtrucks[1].urlName
                  : exampleFoodtrucks[1].id
              }/admin-edit/info`}
            >
              <p className="text-center">Dodaj kolejnego foodtrucka</p>
              <img src={plus} alt="" />
            </AddFoodTrucks>
          </div>
        </Wrapper>
      </SimpleViewsLayout>
      {currentFoodtruck
        ? ReactDOM.createPortal(
            <CreateFoodtruckMenu
              foodtruck={currentFoodtruck}
              onCloseClick={onEditMenuClose}
              ref={creatMenu}
            />,
            body as HTMLBodyElement
          )
        : ''}
    </>
  );
};

export default MyFoodtrucks;
