import React from 'react';
import Dashboard from 'views/Dashboard/Dashboard';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

const FoodtruckerUser = () => {
  const [cookies] = useCookies(['user-token']);
  const navigate = useNavigate();

  if (!cookies['user-token']) {
    navigate('/app/foodtruck-login');
  } else if (cookies['user-token'].userType === 'simple-user')
    navigate('/app/user-simple-dashboard');

  return <Dashboard />;
};

export default FoodtruckerUser;
