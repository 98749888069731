import React, { useEffect, useState } from 'react';
import SimpleViewsLayout from 'components/templates/SimpleViewsLayout/SimpleViewsLayout';
import blackHeart from 'assets/icons/black-heart.svg';
import { exampleFoodtrucks } from 'data/foodtruck';
import FoodtruckListItem from 'components/molecules/FoodtruckListItem/FoodtruckListItem';
import { FoodtruckState } from 'types/Foodtrucktypes';
import { Wrapper } from './FavoritesFoodtrucks.styles';

const FavoritesFoodtrucks = () => {
  const [currentFoodtrucks, setCurrentFoodtruck] = useState<FoodtruckState[]>(exampleFoodtrucks);

  useEffect(() => {
    const newFoodtrucks = [];
    for (let i = 0; i < currentFoodtrucks.length; i++) {
      const newFoodtruck = currentFoodtrucks[i];
      newFoodtruck.menu.dish.map((dish) => {
        const newDish = dish;
        // @ts-ignore
        newDish.DishCategory = newFoodtruck.menu.dishCategories[newDish.DishCategory as number];
      });
      newFoodtrucks[i] = newFoodtruck;
    }

    setCurrentFoodtruck(newFoodtrucks);
  }, []);

  return (
    <SimpleViewsLayout>
      <Wrapper>
        <div className={'grid gap-4 content-center'}>
          <img src={blackHeart} alt="" width="67.5px" />
          <h1 className={'font-semibold text-xl'}>Twoje Ulubione Foodtrucki</h1>
        </div>
        <div>
          {currentFoodtrucks.map((foodtruck) => (
            <FoodtruckListItem key={foodtruck.id} foodtruck={foodtruck} />
          ))}
        </div>
      </Wrapper>
    </SimpleViewsLayout>
  );
};

FavoritesFoodtrucks.propTypes = {};

export default FavoritesFoodtrucks;
