import React from 'react';
import Dashboard from 'views/Dashboard/Dashboard';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

export type UserData = {
  name: string;
  token: string;
  email: string;
};

const SimpleUser = () => {
  const [cookies] = useCookies(['user-token']);
  const navigate = useNavigate();

  if (!cookies['user-token']) {
    navigate('/app/login');
  } else if (cookies['user-token'].userType === 'foodtrucker')
    navigate('/app/foodtrucker-dashboard');

  return <Dashboard />;
};

export default SimpleUser;
