import dish from 'assets/img/dish.jpg';
import { FoodtruckState } from 'types/Foodtrucktypes';
import { v4 as uuidv4 } from 'uuid';
import dishIcon from 'assets/img/dish.jpg';

export const exampleFoodtrucks: FoodtruckState[] = [
  {
    id: uuidv4(),
    name: 'Bafra Kebab Smolec',
    description: 'Foodtruck z Kebabem Smolec',
    location: {
      coordinates: {
        longitude: 16.9068851,
        latitude: 51.0851009
      },
      address: {
        country: 'Poland',
        city: 'Smolec',
        street: 'Bukowa',
        zipcode: '55-080',
        houseNumber: 1,
        district: 'Dolnośląskie'
      }
    },
    openingTime: {
      openingHours: {
        openingHour: 10,
        openingMinute: 0,
        closingHour: 21,
        closingMinute: 0
      },
      openingWeekdays: [
        {
          isOpen: true,
          weekDay: 0
        },
        {
          isOpen: true,
          weekDay: 1
        },
        {
          isOpen: true,
          weekDay: 2
        },
        {
          isOpen: true,
          weekDay: 3
        },
        {
          isOpen: true,
          weekDay: 4
        },
        {
          isOpen: true,
          weekDay: 6
        }
      ]
    },
    menu: {
      dish: [
        {
          id: uuidv4(),
          name: 'Kebab Tortila Mały',
          description: 'pomidory San Marzano D.O.P, mozzarella, świeża bazylia',
          prize: 13.0,
          weight: 20,
          volume: 2,
          quantity: 20,
          isAvailable: true,
          DishCategory: 2,
          VeganLevel: 0,
          SpicyLevel: 2,
          isDrink: false
        },
        {
          id: uuidv4(),
          name: 'Tortila Falafel',
          description: 'pomidory San Marzano D.O.P, mozzarella',
          prize: 16.0,
          weight: 20,
          volume: 2,
          quantity: 20,
          isAvailable: true,
          DishCategory: 3,
          image: `http://localhost:3000${dishIcon}`,
          VeganLevel: 1,
          SpicyLevel: 0,
          isDrink: false
        },
        {
          id: uuidv4(),
          name: 'Kebab w bułce',
          description: '',
          prize: 16.0,
          weight: 20,
          volume: 2,
          quantity: 20,
          isAvailable: true,
          DishCategory: 2,
          VeganLevel: 0,
          SpicyLevel: 1,
          isDrink: false
        }
      ],
      kitchenType: [1, 2, 3],
      dishCategories: [
        {
          name: 'Pizza'
        },
        {
          name: 'Curry'
        },
        {
          name: 'Kebab',
          description:
            'Lorem ipsum dolor sit amet, consectetur adipisicing elit. At enim mollitia praesentium quas saepe tempore.'
        },
        {
          name: 'Tortila'
        }
      ]
    },
    pictures: ['string'],
    isOpen: true,
    comments: [
      {
        title: 'Is awesome climate',
        body: 'Lorem ipsum',
        author: 'Piotr Kułakowski',
        rating: 4,
        additionDate: new Date('2022-01-23T18:57:58.088Z')
      }
    ],
    rating: 4,
    link: 'befra-kebab-smolec'
  },
  {
    id: uuidv4(),
    name: 'Costa Coffee',
    description: 'American Caffee',
    location: {
      coordinates: {
        latitude: 17.0340187,
        longitude: 51.0964311
      },
      address: {
        country: 'Poland',
        city: 'Smolec',
        street: 'Bukowa',
        zipcode: '55-080',
        houseNumber: 1,
        district: 'Dolnośląskie'
      }
    },
    openingTime: {
      openingHours: {
        openingHour: 10,
        openingMinute: 0,
        closingHour: 21,
        closingMinute: 0
      },
      openingWeekdays: [
        {
          isOpen: true,
          weekDay: 0
        },
        {
          isOpen: true,
          weekDay: 1
        },
        {
          isOpen: true,
          weekDay: 2
        },
        {
          isOpen: true,
          weekDay: 3
        },
        {
          isOpen: true,
          weekDay: 4
        },
        {
          isOpen: true,
          weekDay: 6
        }
      ]
    },
    menu: {
      dish: [
        {
          id: uuidv4(),
          name: 'Kebab Tortila Mały',
          description: 'pomidory San Marzano D.O.P, mozzarella, świeża bazylia',
          prize: 13.0,
          weight: 20,
          volume: 2,
          quantity: 20,
          isAvailable: true,
          DishCategory: 2,
          VeganLevel: 0,
          SpicyLevel: 2,
          isDrink: false
        },
        {
          id: uuidv4(),
          name: 'Tortila Falafel',
          description: 'pomidory San Marzano D.O.P, mozzarella',
          prize: 16.0,
          weight: 20,
          volume: 2,
          quantity: 20,
          isAvailable: true,
          DishCategory: 3,
          image: `http://localhost:3000${dishIcon}`,
          VeganLevel: 1,
          SpicyLevel: 0,
          isDrink: false
        },
        {
          id: uuidv4(),
          name: 'Kebab w bułce',
          description: '',
          prize: 16.0,
          weight: 20,
          volume: 2,
          quantity: 20,
          isAvailable: true,
          DishCategory: 2,
          VeganLevel: 0,
          SpicyLevel: 1,
          isDrink: false
        }
      ],
      kitchenType: [3, 5],
      dishCategories: [
        {
          name: 'Pizza'
        },
        {
          name: 'Curry'
        },
        {
          name: 'Kebab',
          description:
            'Lorem ipsum dolor sit amet, consectetur adipisicing elit. At enim mollitia praesentium quas saepe tempore.'
        },
        {
          name: 'Tortila'
        }
      ]
    },
    pictures: ['string'],
    isOpen: true,
    comments: [
      {
        title: 'Is awesome climate',
        body: 'Lorem ipsum',
        author: 'Piotr Kułakowski',
        rating: 4,
        additionDate: new Date('2022-01-23T18:57:58.088Z')
      }
    ],
    rating: 4,
    link: 'befra-kebab-smolec'
  }
];

export const foodtruckExample = exampleFoodtrucks[0];
